<template>
	<div class="system-content">
		<div class="system-left" :style="height">
			<p class="title">目录</p>
			<ul :style="ulheight">
				<li v-for="(item,i) in attList" :key="i" @click="handleImage(i)" :class="current == i || readList.includes(i+1+'') ? 'current' : ''">{{i+1}}.章节</li>
				<li @click="handleImage(attList.length)" :class="current == attList.length ? 'current' : ''">开始考试</li>
			</ul>
		</div>
		<div class="system-right p1">
			<div v-if="current != attList.length" class="image-box">
				<img :src="currentImage" alt="">
			</div>
			<div v-if="current == attList.length" class="image-box1">
				<img class="img1" src="@/assets/images/exam1.png" alt="">
				<img class="img2" src="@/assets/images/exam2.png" alt="">
				<img class="img3" src="@/assets/images/exam3.png" alt="" @click="handleSubmit">
			</div>
		</div>
	</div>
</template>
<script>
import { debounce } from '@/utils/debounce'
export default {
	data() {
		return {
			height: '',
			ulheight: '',
			attList: [],
			current: 0,
			currentImage: '',
			unreadList: [],
			readList: []
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		init() {
			this.height = 'height:' + (window.innerHeight - 180) + 'px;';
			this.ulheight = 'height:' + (window.innerHeight - 250) + 'px;';
			let that = this;
			that.$request.post(
				"getExamAttList",
				true,
				{
					courseId: that.$route.query.courseId
				},
				function (r) {
					if (r.code == "0") {
						that.attList = r.data;
						// 当图片附件数据为空，直接跳转到考试页面 2022-02-10 zoujian --s
						if (that.attList.length === 0) {
							that.$router.push({
								name: 'examBefore',
								query: {
									id: that.$route.query.id,
									courseId: that.$route.query.courseId,
									backBtn: 1
								}
							})
							return
						}
						// 当图片附件数据为空，直接跳转到考试页面 2022-02-10 zoujian --e
						that.attList.splice(0,1);
						that.attList.forEach((item,index) => {
							that.unreadList.push(index+1);
						})
						that.getReadInfo();
					} else {
						that.$message.error(r.msg);
					}
				}
			);

		},
		// 点击切换图片
		handleImage:debounce(function(i) {
			this.current = i;
			if(i != this.attList.length) {
				this.currentImage = this.attList[i].fileAddr;
				let index = this.unreadList.findIndex(num => num == i+1);
				this.unreadList.splice(index,1);
				this.readList.push((this.current+1)+'');
				this.sendReadInfo(this.readList.join(','));
			}
		},1000),
		// 获取阅读章节
		getReadInfo() {
			// return
			let that =  this;
			that.$request.post(
				"readInfo",
				true,
				{
					onlineId: that.$route.query.id
				},
				function (r) {
					if (r.code == "0") {
						//第一次进来
						if(r.data.readNumber == null) {
							that.currentImage = that.attList[0].fileAddr;
							that.unreadList.splice(0,1);
							that.readList = ['1'];
							that.sendReadInfo('1');
						}
						//
						else {
							that.readList = r.data.readNumber.split(',');
							that.current = Number(that.readList[that.readList.length-1]);
							that.unreadList = that.unreadList.filter(item => !that.readList.includes(item+''));
							if(that.current != that.attList.length){
								that.currentImage = that.current == that.attList.length ? '' : that.attList[that.current].fileAddr;
								let index = that.unreadList.findIndex(num => num == that.current+1);
								that.unreadList.splice(index,1);
								that.readList.push((that.current+1)+'');
								that.sendReadInfo(that.readList.join(','));
							}
						}
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 发送阅读章节
		sendReadInfo(readNumber) {
			let that = this;
			that.$request.post(
				"updateReadInfo",
				false,
				{
					onlineId: that.$route.query.id,
					readNumber: readNumber
				},
				function (r) {
					if (r.code == "0") {

					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 考试考试
		handleSubmit() {
			if(this.unreadList.length > 0) {
				this.$message.error('请看完第'+this.unreadList.join(',')+'章节，再进行考试');
				return
			}
			this.$router.push({
				name: 'examBefore',
				query: {
					id: this.$route.query.id,
					courseId: this.$route.query.courseId
				}
			})
		}
	}
}
</script>
<style>
	.p1{
		padding: 15px;
	}
	.p2{
		padding: 0;
	}
</style>
<style lang="scss" scoped>
.system-content{
	display: flex;
	box-sizing: border-box;
	padding-top: 20px;
	.system-left{
		width: 150px;
		background-color: #fff;
		border-radius: 10px;
		box-sizing: border-box;
		padding-top: 25px;
		min-height: 520px;
		// overflow: auto;
		.title{
			height: 30px;
			font-size: 24px;
			margin-bottom: 10px;
			text-align: center;
		}
		ul{
			overflow: auto;
			&::-webkit-scrollbar{
				width:5px;
				height:10px;
			}
			&::-webkit-scrollbar-track{
				// background: rgb(239, 239, 239);
				border-radius:2px;
			}
			&::-webkit-scrollbar-thumb{
				background: #ddd;
				border-radius:5px;
			}
			&::-webkit-scrollbar-thumb:hover{
				background: #333;
			}
			li{
				list-style: none;
				height: 50px;
				line-height: 50px;
				border-bottom: 1px solid #ddd;
				text-align: center;
				background-color: #fff;
				color: rgba(89, 89, 89, 1);
				cursor: pointer;
			}
			.current{
				background: rgba(17, 127, 252, 0.1);
				color: #117FFC;
			}
		}
	}
	.system-right{
		flex: 1;
		background-color: #fff;
		border-radius: 10px;
		margin-left: 10px;
		box-sizing: border-box;
		.image-box{
			width: 100%;
			img{
				width: 100%;
			}
		}
		.image-box1{
			position: relative;
			width: 100%;
			.img1{
				width: 100%
			}
			.img2{
				position: absolute;
				left: 50%;
				margin-left: -343px;
				top: 50%;
				margin-top: -236px;
			}
			.img3{
				position: absolute;
				left: 50%;
				margin-left: -150px;
				top: 50%;
				margin-top: -121px;
				cursor: pointer;
			}
		}
		.submit-btn{
			display: block;
			width: 40%;
			height: 36px;
			border: none;
			outline: none;
			background: #117FFC;
			border-radius: 3px;
			color: #fff;
			margin: 0 auto;
			cursor: pointer;
		}
	}
}
</style>
